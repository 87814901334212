import React from "react";
import "./ChronoLogicMain.css";
import { Carousel, Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";

const contentStyle = {
  margin: 0,
  marginTop: 15,
  height: "63vh",
  color: "#fff",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "120px",
  textAlign: "center",
  background: "#02162d",
  zIndex: 0,
};

function ChronoLogicMain() {
  return (
    <section className="main-container" id="chronologic">
      <div className="header-name">
        <h2>ChronoLogic</h2>
        <p>
          A simple and powerful process mining tool for all your business
          transformation and process improvement needs
        </p>
        <div className="video-trial-buttons">
          <Button type="primary" className="button-video">
            Discover video <PlayCircleOutlined />
          </Button>
        </div>
      </div>
      <div className="carousel">
        <Carousel className="carousel">
          <div className="carousel">
            <div style={contentStyle}>
              <div className="image-1" />
            </div>
          </div>
          <div className="carousel">
            <div style={contentStyle}>
              <div className="image-3" />
            </div>
          </div>
          <div className="carousel">
            <div style={contentStyle}>
              <div className="image-4" />
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
}

export default ChronoLogicMain;
