import {
  BranchesOutlined,
  DatabaseOutlined,
  TableOutlined,
  UnorderedListOutlined,
  BarChartOutlined,
  DotChartOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./FAQ.css";
import { Button, Col, Row } from "antd";

function FAQ() {
  return (
    <section className="faq" id="FAQ">
      <div className="faq-questions">
        <Row gutter={[8, 8]}>
          <Col span={23} className="faq-header">
            <h2>Frequently Asked Questions</h2>
          </Col>
          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>What is process mining?</h3>
            <p>
              Process mining is the use of activity log data generated by your
              systems to recreate the business process digitally, allowing for
              objective insights into and analysis of your business processes
            </p>
          </Col>
          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>How does process mining work?</h3>
            <p>
              Once you’ve extracted activity logs from your system(s) of record,
              the data is grouped and ordered chronologically. This allows for
              visualization and analysis of the business process.
            </p>
          </Col>
          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>Why should I use a process mining tool?</h3>
            <p>
              Process mining is a crucial tool for organizations seeking to
              improve their operations and make data-driven decisions. It offers
              a clear and visual representation of your business processes,
              providing transparency into how work is done, who is involved, and
              how data moves through your systems. This transparency is
              fundamental for optimizing processes, reducing costs, and
              enhancing productivity by identifying inefficiencies and
              bottlenecks
            </p>
          </Col>

          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>What data do I need to mine a process?</h3>
            <p>
              You need three pieces of data extracted from your system(s) of
              record: a process identifier, a list of events, and date/time
              stamps associated with each event. A process identifier is used to
              group the data together into a journey – think Patient Encounter
              Number, MRN, or Account number. Events are the steps that happen
              in the business process – for example, when looking at the
              lifecycle of a hospital account, events could be scheduling,
              insurance verification, obtaining and
              authorization/pre-certification, patient check-in, patient
              check-out, etc.
            </p>
          </Col>
          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>How can my business get started with process mining?</h3>
            <p>
              You need three things to get started with process mining – a
              process you’d like to analyze, the data that is generated by that
              process from your systems of record, and a process mining tool. We
              can help you identify all three things and get you quickly started
              on your process mining journey.
            </p>
          </Col>
          <Col
            span={11}
            className="question"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <h3>Is my business process a good candidate for process mining?</h3>
            <p>
              If your business process is recorded in a system (think ERP, EHR,
              CRM, etc.) and you can access the logs generated by these systems,
              then your business process is a good candidate for process mining
            </p>
          </Col>

          <Col span={23} className="faq-buttons">
            <a href="#contact">
              <Button type="primary" id="button-questions">
                <span>Still Have Questions? Contact Us</span>
              </Button>
            </a>
          </Col>
        </Row>
      </div>
      <div className="faq-header-benefits">
        <p>Benefits of Using our Process Mining Tool</p>
        <Row>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <BranchesOutlined />
            <p className="benefit-description">Process Optimization</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <DatabaseOutlined />
            <p className="benefit-description">Data Transparency</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <TableOutlined />
            <p className="benefit-description">Complex Data Handling</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <UnorderedListOutlined />
            <p className="benefit-description">Pattern Recognition</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <BarChartOutlined />
            <p className="benefit-description">Predictive Analytics</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <DotChartOutlined />
            <p className="benefit-description">Advanced Visualization</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <ExportOutlined />
            <p className="benefit-description">Scalability</p>
          </Col>
          <Col
            className="flex-row faq-benefits-items"
            span={3}
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <ExclamationCircleOutlined />
            <p className="benefit-description">Anomaly Detection</p>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default FAQ;
