import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import { Button, Row, Col, message, Spin } from "antd";

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const contactFormRef = useRef();

  const success = () => {
    message.success("Message successfully sent!");
  };

  const error = () => {
    message.error("Something Went Wrong!");
  };

  const sendEmail = (e) => {
    if (
      contactFormRef.current[0].value === "" ||
      contactFormRef.current[2].value === ""
    ) {
      setIsLoading(false);
      return message.warning("Please provide contact information");
    }

    setIsLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ggmy8li",
        "template_u6mrfwp",
        contactFormRef.current,
        "-eI0lWJBazM3rJmcL"
      )
      .then(
        (result) => {
          success();
        },
        (errorMessage) => {
          error();
        }
      );
    setIsLoading(false);
    contactFormRef.current.reset();
  };

  return (
    <section className="contact-container" id="contact">
      <h2>Contact Us</h2>
      <p>We love to hear from prospective and existing customers!</p>
      <form action="" ref={contactFormRef} onSubmit={sendEmail}>
        <Row className="form" gutter={[8, 8]}>
          <Col className="input" span={10} xs={22} lg={10}>
            <input
              placeholder="First Name"
              required
              type={"text"}
              name="user_first_name"
            />
          </Col>
          <Col className="input" span={10} xs={22} lg={10}>
            <input
              placeholder="Last Name"
              type={"text"}
              name="user_last_name"
            />
          </Col>
          <Col className="input" span={10} xs={22} lg={10}>
            <input
              placeholder="E-Mail Address"
              type={"email"}
              required
              name="from_email"
            />
          </Col>
          <Col className="input" span={10} xs={22} lg={10}>
            <input placeholder="Phone Number" name="user_phone_number" />
          </Col>
          <Col className="input" span={22} lg={22}>
            <input
              id="company-input"
              placeholder="Company / Academic Institution"
              name="user_company_name"
            />
          </Col>
          <Col className="input" span={10} xs={22} lg={10}>
            <input placeholder="City" name="user_city" />
          </Col>
          <Col className="input" span={10} xs={22} lg={10}>
            <input placeholder="Country" name="user_country" />
          </Col>
          <Col className="input" span={16} xs={22} md={16}>
            <textarea
              name="user_message"
              id="textarea"
              placeholder="Message ..."
            />
          </Col>
          <Col span={20} className="input">
            <Button type="primary" id="button-submit" onClick={sendEmail}>
              {isLoading ? <Spin /> : <span>Submit</span>}
            </Button>
          </Col>
        </Row>
      </form>
    </section>
  );
}

export default Contact;
