import React from "react";
import { Col, Row } from "antd";
import "./Healthcare.css";

function Healthcare() {
  return (
    <section className="healthcare-container" id="healthcare">
      <p className="healthcare-container-header">Healthcare Use Cases</p>
      <div className="healthcare">
        <p className="healthcare-description-use-case-header">
          Minimum Data Set (MDS) Compliance
        </p>
        <Row>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Background</p>
            <ul>
              <li className="healthcare-description-item">
                The Contact Center implemented a Protocol of collecting and
                verifying patient information to reduce insurance claims denials
                using insights derived from Process Mining
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Goals/Challenges</p>
            <ul>
              <li className="healthcare-description-item">
                Reduce front-end denials and re-processing expense for insurance
                claims
              </li>
              <li className="healthcare-description-item">
                Improve quality of patient information collection during
                scheduling of patient services/procedures
              </li>
              <li className="healthcare-description-item">
                Identify opportunities to improve workforce optimization and
                compliance with processes
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Results</p>
            <ul>
              <li className="healthcare-description-item">
                Compliance rate with accurate data collection increased from 40%
                to 87%
              </li>
              <li className="healthcare-description-item">
                Audit team is now able to monitor compliance for 100% of all
                scheduler interactions with patients; up from ~5% before process
                mining
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="healthcare">
        <p className="healthcare-description-use-case-header">
          Real-Time Eligibility
        </p>
        <Row>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Background</p>
            <ul>
              <li className="healthcare-description-item">
                In the pursuit of verifying all patient insurance prior to
                arrival, the organization is at risk of increased costs due to
                extraneous insurance verifications
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Goals/Challenges</p>
            <ul>
              <li className="healthcare-description-item">
                Make sure every patient’s insurance is verified prior to
                rendering services
              </li>
              <li className="healthcare-description-item">
                Limited insight into insurance verification controls
              </li>
              <li className="healthcare-description-item">
                Business operation’s expectations and supporting system build
                not aligned
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Results</p>
            <ul>
              <li className="healthcare-description-item">
                Identified potential $5.5M cost savings from duplicate insurance
                verification batch jobs
              </li>
              <li className="healthcare-description-item">
                275+ insurance verifications per patient within a 30-day period
              </li>
              <li className="healthcare-description-item">
                Identified systems configuration issues (insurance eligibility
                batch jobs set up incorrectly) and user training gaps (check-in
                staff not verifying insurance at check-in)
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="healthcare">
        <p className="healthcare-description-use-case-header ">
          Point-of-Service Collections
        </p>
        <Row>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Background</p>
            <ul>
              <li className="healthcare-description-item">
                Tracking compliance for co-pay collections across all ambulatory
                front-end users; identifying when enterprise policies aren’t
                followed, and who’s responsible
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Goals/Challenges</p>
            <ul>
              <li className="healthcare-description-item">
                Enterprise policies are in place for all ambulatory front-end
                users to collect co-pays at the POS
              </li>
              <li className="healthcare-description-item">
                It is difficult to identify if policies are being followed with
                current tools
              </li>
              <li className="healthcare-description-item">
                The organization has money at-risk, but we don’t know how much
                and who is responsible
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Results</p>
            <ul>
              <li className="healthcare-description-item">
                Identified ~$480,000 Co-Pays due, but where no monies were
                collected (in a 30-day period)
              </li>
              <li className="healthcare-description-item">
                Estimated $60,000 cost to collect missed co-pays per month
              </li>
              <li className="healthcare-description-item">
                Identified the department and users with the largest opportunity
                for improvement – 23% co-pay collection non-compliance rate
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="healthcare">
        <p className="healthcare-description-use-case-header">Denials</p>
        <Row>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Background</p>
            <ul>
              <li className="healthcare-description-item">
                Understanding root causes for claim denials, especially related
                to avoidable denials; identifying who in the organization is not
                following established policies and procedures leading to denials
                and where/when it is happening
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Goals/Challenges</p>
            <ul>
              <li className="healthcare-description-item">
                Policies and procedures exist for the enterprise revenue cycle
                group, but it is difficult to track compliance to these policies
                with existing tools
              </li>
              <li className="healthcare-description-item">
                The organization is losing money on each denial, both from and
                administrative burden (appealing the denial) and from a
                non-recoupment perspective
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Results</p>
            <ul>
              <li className="healthcare-description-item">
                Identified ~$750,000 in denial rework costs related to insurance
                verification non-compliance over 6 months of data
              </li>
              <li className="healthcare-description-item">
                Identified 21 individuals responsible for non-compliance leading
                to denials, one of whom was responsible for 38% of all denials
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="healthcare">
        <p className="healthcare-description-use-case-header">
          Infusion Authorizations
        </p>
        <Row>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Background</p>
            <ul>
              <li className="healthcare-description-item">
                Searching for bottlenecks in obtaining authorizations for
                infusion services prior to scheduling an infusion appointment;
                identifying users not following the proper process and payors
                who are requiring authorizations with a high approval rate
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Goals/Challenges</p>
            <ul>
              <li className="healthcare-description-item">
                Providers are required to place two orders in the system, one
                for the infusion treatment plan and one for the scheduling of
                the appointment; difficult to gauge provider compliance to
                placing both orders with existing tools
              </li>
              <li className="healthcare-description-item">
                Providers believe the time it takes to authorize infusion
                services is inconsistent, leading to delays in patient care
              </li>
              <li className="healthcare-description-item">
                Authorizations are not always worked immediately by staff, and
                systematically identifying which auth/cert records have not been
                touched within 7 days of entering the WQ is difficult
              </li>
            </ul>
          </Col>
          <Col
            span={7}
            className="healthcare-description"
            xs={{ span: 23, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
          >
            <p className="healthcare-description-header">Results</p>
            <ul>
              <li className="healthcare-description-item">
                Identified top 4 providers who do not place scheduling order
                after placing a treatment plan order, delaying patient care
              </li>
              <li className="healthcare-description-item">
                Identified 3 payors with auth approval rates above 80%,
                prompting a data-driven conversation with payor relations around
                gold-carding infusion services for these payors
              </li>
              <li className="healthcare-description-item">
                Identified 56 authorizations (~16% of volume) not touched by
                financial clearance staff within 7 days of entering the
                authorization WQ
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Healthcare;
