import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import "./ProcessMining.css";
import { PlayCircleOutlined } from "@ant-design/icons";

function ProcessMining() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="process-mining-container" id="process-mining">
      <Row gutter={[8, 8]}>
        <Col span={12} xs={24} lg={12} className="description">
          <div className="description-left">
            <h2>What Is Process Mining?</h2>
            <p>
              Most business processes are executed in centralized systems of
              record - ERPs, EHRs, CRMs, etc. These centralized systems generate
              massive amounts of data in the form of access logs that track
              system and user behavior. We use that data to digitally recreate
              the process, exactly the way it happened, to glean actionable
              insights for your business.
            </p>
            <Link to="/" onClick={() => scrollToSection("chronologic")}>
              HOW DOES IT WORK <PlayCircleOutlined className="playIcon" />
            </Link>
          </div>
        </Col>
        <Col
          span={12}
          xs={24}
          lg={12}
          className="description"
          id="description-right"
        >
          <div className="description-right" />
        </Col>
        <Col span={12} xs={24} lg={12} className="graphics" id="graphics">
          <div className="graphics-left" />
        </Col>
        <Col span={12} xs={24} lg={12} className="graphics" id="graphics">
          <div className="graphics-right">
            <h2>Industry Expertise Combined With Cutting Edge Technology</h2>
            <p>
              We firmly believe that the most effective solutions combine deep
              knowledge of operational processes, understanding of the data
              structures of systems that support those processes, and
              cutting-edge technology that helps bring improvement opportunities
              to light. Our team’s expertise in all three of these domains is
              what sets us apart from the competition.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default ProcessMining;
