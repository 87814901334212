import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Button, Space, Drawer } from "antd";
import "./HeaderLayout.css";

function HeaderLayout() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="container-fluid">
      <div className="header">
        <div className="logo-container">
          <div className="logo" />
          <Link
            to="/chronologic"
            onClick={() => scrollToSection("chronologic")}
          >
            <h2 id="companyName">TimeLabs</h2>
          </Link>
        </div>
        <div className="nav-links">
          <Link
            to="/chronologic"
            onClick={() => scrollToSection("chronologic")}
          >
            Home
          </Link>
          <Link
            to="/process-mining"
            onClick={() => scrollToSection("process-mining")}
          >
            What Is Process Mining?
          </Link>
          <Link to="/healthcare" onClick={() => scrollToSection("healthcare")}>
            Healthcare
          </Link>
          <Link to="/FAQ" onClick={() => scrollToSection("FAQ")}>
            FAQ
          </Link>
          <Link to="/contact" onClick={() => scrollToSection("contact")}>
            Contact Us
          </Link>
        </div>
        <div className="button-container">
          <Space wrap>
            <Button
              type="primary"
              className="button-register"
              onClick={() => (window.location.href = "http://cl.timelabs.ai")}
              icon={<UserOutlined />}
            >
              Log In
            </Button>
          </Space>
        </div>
        <div className="drawer">
          <div className="logo" onClick={showDrawer} />
          <Drawer
            title="TimeLabs"
            placement="right"
            onClose={onClose}
            open={open}
            className="drawer-component"
          >
            <div className="drawer-content">
              <Link
                to="/chronologic"
                onClick={() => scrollToSection("chronologic")}
              >
                Home
              </Link>
              <Link
                to="/process-mining"
                onClick={() => scrollToSection("process-mining")}
              >
                What Is Process Mining?
              </Link>
              <Link
                to="/healthcare"
                onClick={() => scrollToSection("healthcare")}
              >
                Healthcare
              </Link>
              <Link to="/FAQ" onClick={() => scrollToSection("FAQ")}>
                FAQ
              </Link>
              <Link to="/contact" onClick={() => scrollToSection("contact")}>
                Contact Us
              </Link>
            </div>
          </Drawer>
        </div>
      </div>
    </section>
  );
}

export default HeaderLayout;
